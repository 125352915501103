import React, { useContext, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useDispatch} from 'react-redux';
import styled, { css } from 'styled-components';

import TypeSite from '@logic/enums/TypeSite';
import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import RegistrationDataValidator from '@logic/forms/validators/validators/RegistrationDataValidator';
import StateUpdater from '@logic/helpers/StateUpdater/StateUpdater';
import registration from '@logic/language/ex/landing/auth/registration';
import {RegistrationData} from '@logic/models/Auth/RegistrationData';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setRegistration} from '@store/reducers/auth/authSlice';

import Button from '@components/common/buttons/Button';
import { IconPosition } from '@components/common/buttons/buttonStyles';
import LinkButton from '@components/common/buttons/LinkButton';
import { Input } from '@components/common/inputs/BaseInput/Input';
import { Checkbox } from '@components/common/inputs/Checkbox/Checkbox';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { mediaBreakpointDown } from '@components/styledComponents/base/functions';
import PasswordInput from '@src/common/inputs/PasswordInput/PasswordInput';
import PhoneInput from '@src/common/inputs/PhoneInput/PhoneInput';
import {RegistrationContext} from '@src/landing/modals/auth/registration/RegistrationContext';
import { Colors } from '@src/styledComponents/base/Colors';
import { typography } from '@src/styledComponents/base/typography';

import ArrIcon from '@public/icons/baseUI/arrow-icons/long_arrow.svg';

import {CaptchaAction} from '@landing/modals/auth/registration/ReCaptchaComponent';

export default function RegistrationForm () {

    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { language, site } = useAppSelector((state) => state.generalStatesSlice);
    const { registrationData, setRegistrationData, predefinedEmail } = useContext(RegistrationContext);
    const [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState<PayAssistantError | null>(null);
    const [validatorResult, setValidatorResult] = useState(new ValidatorResult<RegistrationData>());

    const getCaptchaToken = async () => {
        if (executeRecaptcha && process.env.NEXT_PUBLIC_MODE !== 'test') {
            return await executeRecaptcha(CaptchaAction.REGISTER);
        }
        return '';
    };

    const setFieldValue = StateUpdater
        .builder<RegistrationData, RegistrationData>(setRegistrationData)
        .setOnUpdate((key) => {
            setValidatorResult((prev) => {
                prev.removeError(key);
                return prev;
            });
        })
        .build()
        .get();

    const validator = new RegistrationDataValidator();

    const apiService = usePaApiService(ApiService, ['password', 'firstName', 'lastName', 'phone', 'email'], {
        ru: `Регистрация`,
        en: `Sign up`,
        lt: `Registracija`,
        uz: `Ro'yxatdan o'tish`
    });

    const handleAgreement = () => setFieldValue(`agreeWithTerms`, !registrationData.agreeWithTerms);

    const sendForm: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        const formValidatorResult = validator.validate(registrationData);

        if (registrationData.password !== registrationData.repeatPassword) {
            formValidatorResult.setError(`repeatPassword`, {
                ru: `Пароли не совпадают`,
                en: `Passwords don't match`,
                lt: `Slaptažodžiai nesutampa`,
                uz: `Parollar bir xil emas`,
            });
        }

        setValidatorResult(formValidatorResult);

        if (!formValidatorResult.isSuccess()) return;
        setLoading(true);

        const token = await getCaptchaToken();
        apiService.registrationFirstStep({
            role: registrationData.role,
            firstName: registrationData.firstName,
            lastName: registrationData.lastName,
            email: registrationData.email,
            phone: registrationData.phone.number,
            password: registrationData.password,
            country: registrationData.phone.country,
            reCaptchaResponse: token
        }).then(response => {
            setLoading(false);
            if (!response.isSuccess()) {
                setErrorResponse(ApiErrorAdapter.getPayAssistantError(response.getError()));
                return;
            }
            dispatch(setRegistration(response.getData()));
        });
    };

    return (
        <Content>
            <Form onSubmit={sendForm}>
                <PersonalInfo>
                    <Heading>
                        <Title>
                            {registration.personalInfoHeading[language]}
                        </Title>
                        <Text>
                            {registration.personalInfoCaption[language]}
                        </Text>
                    </Heading>
                    <Fields>
                        <Input
                            name={'firstName'}
                            label={registration.labelName[language]}
                            isRequired
                            placeholder={registration.placeholderName[language]}
                            value={registrationData.firstName ?? ``}
                            preventOnInvalidChar
                            setValue={(v) => setFieldValue('firstName', v.replaceAll(/[^a-zA-Zа-яА-ЯёЁ]+/gu, ''))}
                            isError={validatorResult.hasError('firstName')}
                            errorMessage={validatorResult.getMessageByLanguage('firstName', language)}
                        />
                        <Input
                            name={'lastName'}
                            label={registration.labelSurname[language]}
                            isRequired
                            placeholder={registration.placeholderSurname[language]}
                            value={registrationData.lastName ?? ``}
                            preventOnInvalidChar
                            setValue={(v) => {
                                setFieldValue('lastName', v.replaceAll(/[^a-zA-Zа-яА-ЯёЁ]+/gu, ''));
                            }}
                            isError={validatorResult.hasError('lastName')}
                            errorMessage={validatorResult.getMessageByLanguage('lastName', language)}
                        />
                        <Input
                            name={'email'}
                            label={registration.labelEmail[language]}
                            isRequired
                            placeholder={registration.placeholderEmail[language]}
                            value={predefinedEmail ? predefinedEmail : registrationData.email ?? ``}
                            preventOnInvalidChar
                            disabled={!!predefinedEmail}
                            setValue={(v) => {
                                if(predefinedEmail) return;
                                setFieldValue('email', v.replaceAll(' ', ''));
                            }}
                            isError={validatorResult.hasError('email') || !!errorResponse?.getErrorTranslationByField('email', language)}
                            errorMessage={validatorResult.getMessageByLanguage('email', language) || errorResponse?.getErrorTranslationByField('email', language)}
                        />
                        <PhoneInput
                            name='phone'
                            label={registration.labelPhone[language]}
                            isRequired
                            phoneValue={registrationData.phone}
                            setPhoneValue={(value) => setFieldValue('phone', value)}
                            isError={validatorResult.hasError('phone') || !!errorResponse?.getErrorTranslationByField('phone', language)}
                            errorMessage={validatorResult.getMessageByLanguage('phone', language) || errorResponse?.getErrorTranslationByField('phone', language)}
                        />
                    </Fields>
                    <Fields>
                        <PasswordInput
                            name='password'
                            label={registration.labelPassword[language]}
                            isRequired
                            placeholder={registration.placeholderPassword[language]}
                            value={registrationData.password}
                            setValue={(value) => setFieldValue('password', value)}
                            responseError={errorResponse?.getErrorTranslationByField('password', language)}
                            isError={validatorResult.hasError('password')}
                            errorMessage={validatorResult.getMessageByLanguage('password', language)}
                        />
                        <PasswordInput
                            name='repeatPassword'
                            label={registration.labelRepeatPassword[language]}
                            isRequired
                            placeholder={registration.placeholderRepeatPassword[language]}
                            value={registrationData.repeatPassword}
                            setValue={(value) => setFieldValue('repeatPassword', value)}
                            responseError={errorResponse?.getErrorTranslationByField('repeatPassword', language)}
                            isError={validatorResult.hasError('repeatPassword')}
                            errorMessage={validatorResult.getMessageByLanguage('repeatPassword', language)}
                        />
                    </Fields>
                </PersonalInfo>
                <AgreementsAndButtonContainer>
                    <Agreements>
                        <CheckboxContainer>
                            <Checkbox
                                isCheck={registrationData.agreeWithTerms}
                                onChange={handleAgreement}
                                isError={validatorResult.hasError('agreeWithTerms')}
                            />
                        </CheckboxContainer>
                        <Statement>
                            {registration.agreementsByClicking[language]}&nbsp;
                            <LinkButton as='Link' href={`/`} target={`_blank`}>
                                {registration.agreementsPrivacyPolicy[language]}
                            </LinkButton>
                            &nbsp;
                            {
                                // TODO is temporary solution
                                site === TypeSite.COM && <LinkButton as='a' >
                                    {registration.agreementsOffer[language]}
                                </LinkButton>
                            }
                            {
                                site !== TypeSite.COM && <LinkButton as='Link' href={`/public-offer`} target={`_blank`}>
                                    {registration.agreementsOffer[language]}
                                </LinkButton>
                            }
                            &nbsp;
                            {registration.agreementsAnd[language]}&nbsp;
                            <LinkButton as='Link' href={`/`} >
                                {registration.agreementsUserAgreement[language]}
                            </LinkButton>
                        </Statement>
                    </Agreements>
                    <ButtonContainer>
                        <Button
                            styles={buttonStyles}
                            type='submit'
                            isLoading={loading}
                            disabled={loading || !registrationData.firstName || !registrationData.lastName || !registrationData.email || !registrationData.phone || !registrationData.password || !registrationData.repeatPassword}
                            Icon={<ArrIcon/>}
                            iconPosition={IconPosition.RIGHT}>
                            {registration.buttonNext[language]}
                        </Button>
                        {/*<InputArrayError show={!!generalError} messages={generalError} unrefrigibleErrors={unrefrigibleErrors}/>*/}
                    </ButtonContainer>
                </AgreementsAndButtonContainer>
            </Form>
        </Content>
    );
}

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Form = styled.form`
    height: 100%;
    width: 100%;
    max-width: 496px;
    max-height: 610px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        justify-content: flex-start;
        gap: 32px;
    }
`;

const Heading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Text = styled.div`
    ${typography.captions};
    color: ${Colors.GRAY_600};
`;

const PersonalInfo = styled.div<{ withPadding?: boolean }>`
    width: 100%;
    display: grid;
    grid-auto-rows: max-content;
    grid-gap: 16px;
    padding-top: ${({ withPadding }) => withPadding && '32px'};
`;

const Fields = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    
    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        grid-template-columns: unset;
        grid-template-rows: max-content max-content;
        width: 240px;
    }
`;

const AgreementsAndButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Agreements = styled.div`
    display: flex;
    gap: 16px;
    align-items: flex-start;
`;

const CheckboxContainer = styled.div`
    margin: 3px;
`;

const Statement = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_600};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: left;
`;

const buttonStyles = css`
    width: 260px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 190px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        width: 156px;
    }
`;
