import React, { useState} from 'react';
import styled, { css } from 'styled-components';

import login from '@logic/language/ex/landing/auth/login';
import registration from '@logic/language/ex/landing/auth/registration';
import { LoginSteps } from '@logic/models/Auth/AuthSteps';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ConfirmationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/ConfirmationResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppDispatch, useAppSelector} from '@store/hooks/hooks';
import {setAuthSession} from '@store/reducers/user/userSlice';

import IconButton from '@components/common/buttons/IconButton';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { mediaBreakpointDown } from '@components/styledComponents/base/functions';

import ArrIcon from '@public/icons/baseUI/arrow-icons/long_arrow.svg';

import ConfirmationAuthLayout from '@landing/modals/auth/ConfirmationAuthLayout';

type Props = {
    confirmations: ConfirmationResponse | null,
    setLoginStep: React.Dispatch<React.SetStateAction<LoginSteps>>,
    phone: string
};

export default function CodeForm (props: Props) {
    const {confirmations, setLoginStep, phone} = props;

    const dispatch = useAppDispatch();
    const apiService = usePaApiService(ApiService);
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const [sendLoading, setSetLoading] = useState(true);

    const sendCode = async (): Promise<{resendRemain: number, isSuccess: boolean, error: PayAssistantError | null}> => apiService.sendLoginCode()
        .then(response => {
            if (!response.isSuccess()) {
                return {resendRemain: 0, isSuccess: false, error: response.getError()};
            }
            return {resendRemain: response.getData().resendRemain, isSuccess: true, error: null};
        })
        .finally(() => setSetLoading(false));

    const getSessionState = (): {needSend: boolean, resendRemain: number} => {
        if (!confirmations) {
            return {needSend: true, resendRemain: 90};
        }
        return { needSend: confirmations.canResend, resendRemain: confirmations.resendRemain };
    };

    const checkCode = async (code: string): Promise<PayAssistantError | null> => {
        const response = await apiService.checkLoginCode({code});
        if (!response.isSuccess()) {
            return ApiErrorAdapter.getPayAssistantError(response.getError());
        }
        dispatch(setAuthSession(response.getData()));
        return null;
    };

    return (
        <Content>
            <IconButton
                styles={backButton}
                label={login.labelBackButton[language]}
                Icon={<ArrIcon/>}
                onClick={()=>setLoginStep(LoginSteps.SEND_CREDENTIALS)}/>
            <ConfirmationAuthLayout
                getSessionState={getSessionState}
                description={`
                        ${registration.phoneConfirmationTextBefore[language]}
                        ${phone}
                        ${registration.phoneConfirmationTextAfter[language]}
                `}
                sendCode={sendCode}
                sendLoading={sendLoading}
                checkCode={checkCode}
                buttonText={login.submitButtonText[language]}
            />
        </Content>
    );
}

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 15px;

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        position: static;
    }
`;

const backButton = css`
    position: absolute;
    top: 20px;
    left: 20px;
    transform: rotate(-180deg);
`;
