import React, { useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';

import {UserRole} from '@logic/models/Auth/UserRole';
import {Tokens} from '@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse';
import {LandingContext} from '@context/LandingContext';

import {useAppSelector} from '@store/hooks/hooks';
import {offLoading, turnLoading} from '@store/reducers/generalStates/generalStatesSlice';

import {AuthModalState} from '@src/landing/modals/auth/AuthModal';
import Registration from '@src/landing/modals/auth/registration/Registration';

import Login from '@landing/modals/auth/login/Login';
import PasswordRecovery from '@landing/modals/auth/passwordRecovery/PasswordRecovery';

type Props = {
    email: string
};

export default function Authorization (props: Props) {
    const {email } = props;

    const router = useRouter();
    const dispatch = useDispatch();
    const { authModalState, setAuthModalState } = useContext(LandingContext);
    const user = useAppSelector((state) => state.userSlice);
    const session = useAppSelector((state) => state.authSlice);
    const { routIsLoading } = useAppSelector(state => state.generalStatesSlice);

    const roleMap = new Map([
        [UserRole.Freelancer, '/freelancer-account'],
        [UserRole.Company, '/company-account'],
        [UserRole.EMPLOYEE, '/company-account'],
    ]);
       
    const getUrlByRole = () => roleMap.get(user.data.role) ?? '/404';

    useEffect(() => {
        if (!user.loaded || authModalState === AuthModalState.Closed) {
            return;
        }
        if (routIsLoading) {
            return;
        }

        dispatch(turnLoading());

        if (user.data.role === UserRole.EMPLOYEE) {
            localStorage.removeItem(Tokens.ACCESS_TOKEN);
            localStorage.removeItem(Tokens.REFRESH_TOKEN);
            window.location.reload();
            return;
        }

        router.push(getUrlByRole())
            .finally(()=>{
                setAuthModalState(AuthModalState.Closed);
                dispatch(offLoading());
            });
    }, [user, authModalState]);

    return (
        <>
            <Login isOpen={authModalState === AuthModalState.Login} />
            <Registration email={email} isOpen={authModalState === AuthModalState.Register}/>
            <PasswordRecovery isOpen={authModalState === AuthModalState.Recover && session.loaded}/>
        </>
    );

}