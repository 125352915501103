import {ReactNode, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setSession} from '@store/reducers/auth/authSlice';

type PropsType = {
    onLoadingError?: (response: ApiResponse<any>) => void;
    children: ReactNode
};

export default function SessionLoader ({onLoadingError, children}: PropsType)
{
    const dispatch = useDispatch();

    const session = useAppSelector((state) => state.authSlice);
    const apiService = usePaApiService(ApiService);


    useEffect(() => {
        if(!session.loaded) {
            apiService.getSession()
                .then((result) => {
                    if(result.isServerError()) {
                        if(onLoadingError) onLoadingError(result);
                        return;
                    }
                    if(!result.isSuccess()) {
                        if(onLoadingError) onLoadingError(result);
                        return;
                    }
                    dispatch(setSession(result.getData()));
                });
        }
    }, [session]);

    return <>
        {children}
    </>;
}