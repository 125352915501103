/* eslint-disable @typescript-eslint/no-empty-function */
import React, {Dispatch, SetStateAction} from 'react';

import RuleMessage from "@logic/forms/validators/rules/RuleMessage";

import {AuthModalState} from '@src/landing/modals/auth/AuthModal';


export interface LandingContextType {
    authModalState: AuthModalState,
    setAuthModalState: Dispatch<SetStateAction<AuthModalState>>,

    authModalStateIsOpen: boolean,

    applicationFormOptions: { isOpen: boolean, title?: RuleMessage },
    setApplicationFormOptions: Dispatch<SetStateAction<{ isOpen: boolean, title?: RuleMessage }>>,
}

const LandingContext = React.createContext<LandingContextType>({
    authModalState: AuthModalState?.Login,
    setAuthModalState: () => {},
    authModalStateIsOpen: false,
    applicationFormOptions: { isOpen: false },
    setApplicationFormOptions: () => {},
});

export {LandingContext};