export enum LoginSteps {
    SEND_CREDENTIALS = 'send_credentials',
    CHECK_CODE = 'send_code',
}

export enum PasswordRecoverySteps {
    EnterEmail,
    VerifyCode,
    ResetPassword
}

export enum RegistrationSteps {
    SelectRole,
    FillUserData,
    ConfirmPhone,
    ConfirmEmail,
    Loading
}
