import {ReactNode, useEffect, useState} from 'react';
import {useRouter} from 'next/router';

import ApiResponse from '@logic/service/ApiRequest/ApiResponse';
import {Tokens} from '@logic/service/ApiRequest/ResponseTypes/Token/TokenResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppDispatch, useAppSelector} from '@store/hooks/hooks';
import { setUser} from '@store/reducers/user/userSlice';
// importer {setUser} from '@store/reducers/cards/userSlice';

type PropsType = {
    children: ReactNode
    onLoadingError?: (response: ApiResponse<any>) => void;
    setIsLoading?: (loading: boolean) => void,
};

export default function SignInLoader ({setIsLoading, children}: PropsType)
{
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.userSlice);

    const router = useRouter();
    const apiService = usePaApiService(ApiService);

    const [userIsLoading, setUserIsLoading] = useState(false);

    const getUser = async () =>{
        const response = await apiService.getUser();
        if(response.isSuccess()) {
            dispatch(setUser(response.getData()));
        } else {
            localStorage.removeItem(Tokens.REFRESH_TOKEN);
            localStorage.removeItem(Tokens.ACCESS_TOKEN);
            router.push('/');
        }
        if(response.isServerError()) {

        }
        return response;
    };

    const fetchData = async () => getUser();


    useEffect(()=>{
        if(user.loaded || !localStorage.getItem(Tokens.ACCESS_TOKEN)) return setUserIsLoading(false);
        setUserIsLoading(true);
        fetchData().finally(()=>{
            if(setIsLoading) {
                setIsLoading(false);
            }
            setUserIsLoading(false);
        });
    }, []);


    return (
        <>
            {!userIsLoading && children}
        </>
    );
}