import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import registration from '@logic/language/ex/landing/auth/registration';
import { RegistrationSteps } from '@logic/models/Auth/AuthSteps';
import { RegistrationData } from '@logic/models/Auth/RegistrationData';
import { UserRole } from '@logic/models/Auth/UserRole';
import ApiService from '@logic/service/ApiService/ApiService';
import { LandingContext } from '@context/LandingContext';
import { usePaApiService } from '@hooks/usePaApiService';

import { useAppSelector } from '@store/hooks/hooks';
import { setUserIsLogin } from '@store/reducers/auth/authSlice';
import { turnLoading } from '@store/reducers/generalStates/generalStatesSlice';
import { setAuthSession } from '@store/reducers/user/userSlice';

import ServerError from '@src/common/messageBaners/ServerError/ServerError';
import EmailConfirmation from '@src/landing/modals/auth/registration/EmailConfirmation';
import PhoneConfirmation from '@src/landing/modals/auth/registration/PhoneConfirmation';
import { RegistrationContext } from '@src/landing/modals/auth/registration/RegistrationContext';
import RegistrationForm from '@src/landing/modals/auth/registration/RegistrationForm';
import RoleSelector from '@src/landing/modals/auth/registration/RoleSelector';

import AuthContainer from '@landing/modals/auth/AuthContainer';
import { AuthModalState } from '@landing/modals/auth/AuthModal';

import AuthLayout from '../AuthLayout';

type Props = {
    email: string;
    isOpen: boolean;
};

export default function Registration (props: Props) {
    const { email, isOpen } = props;

    const router = useRouter();
    const dispatch = useDispatch();
    const session = useAppSelector((state) => state.authSlice);
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const { setAuthModalState } = useContext(LandingContext);
    const apiService = usePaApiService(ApiService);
    const [step, setStep] = useState<RegistrationSteps>(RegistrationSteps.SelectRole);
    const [completeError, setCompleteError] = useState<boolean>(false);
    // TODO add reg byh token letter
    const [predefinedEmail, setPredefinedEmail] = useState<string>(email ?? '');
    const [registrationData, setRegistrationData] = useState<RegistrationData>({
        role: session.data.registration?.user?.userRole ?? (router.query.token && typeof router.query.token === 'string') ? UserRole.Freelancer : UserRole.NotSelected,
        firstName: session.data.registration?.user?.firstName ?? ``,
        lastName: session.data.registration?.user?.lastName ?? ``,
        email: session.data.registration?.user?.email ?? email ?? '',
        phone: {
            number: session.data.registration?.user?.phone ?? ``,
            country: session.data.registration?.user?.country ?? ``,
        },
        password: ``,
        repeatPassword: ``,
        agreeWithTerms: false,
    });

    const complete = () => {
        // setStep(RegistrationSteps.Loading);
        dispatch(turnLoading());
        apiService.registrationComplete().then(response => {
            if(response.isSuccess()) {
                dispatch(setAuthSession(response.getData()));
                dispatch(setUserIsLogin());
            }else{
                setCompleteError(true);
            }
        });
    };

    useEffect(() => {
        if (!session.loaded || !session.data.registration || session.data?.login?.authorized) return;

        const token = router.query.token;

        if (!session.data.registration.isFilled) {
            if (token && typeof token === 'string') {
                setStep(RegistrationSteps.FillUserData);
                return;
            }
            setStep(RegistrationSteps.SelectRole);
            return;
        }

        if (!session.data.registration.confirmations.phone.isConfirmed) {
            setStep(RegistrationSteps.ConfirmPhone);
            return;
        }

        if (!session.data.registration.confirmations.email.isConfirmed) {
            setStep(RegistrationSteps.ConfirmEmail);
            return;
        }

        complete();
    }, [session]);

    /**
     * Загружаем информацию о токене
     * */
    // useEffect(() => {
    //     if(registrationData.token) {
    //         apiService.sendGet<FindToken, FindTokenResponse>(ApiMethods.FindToken, {
    //             token: registrationData.token
    //         }).then((result) => {
    //             if(result.isSuccess()) {
    //                 setPredefinedEmail(result.getData()?.email ?? null);
    //             }
    //         });
    //     }
    // }, []);

    if (completeError) {
        return <ServerError/>;
    }

    const registrationSteps = [
        { step: RegistrationSteps.SelectRole, text: registration.mapStepRoleSelection[language] },
        { step: RegistrationSteps.FillUserData, text: registration.mapStepPersonalInfo[language] },
        { step: RegistrationSteps.ConfirmPhone, text: registration.mapStepPhoneConfirmation[language] },
        { step: RegistrationSteps.ConfirmEmail, text: registration.mapStepEmailConfirmation[language] },
    ];

    const data = {
        sidebarTitle: registration.mapHeading[language],
        sidebarText: registration.mapText[language],
        sidebarLinkText: registration.mapLinkText[language],
    };

    return (
        <AuthContainer isOpen={isOpen}>
            <RegistrationContext.Provider value={{predefinedEmail, registrationData, setRegistrationData, step, setStep}}>
                <AuthLayout
                    context={RegistrationContext}
                    steps={registrationSteps}
                    data={data}
                    onClick={() => {
                        router.push(router.asPath.replace('sign-up', 'sign-in'));
                        setAuthModalState(AuthModalState.Login);
                    }}
                >
                    {step === RegistrationSteps.SelectRole && <RoleSelector/>}
                    {step === RegistrationSteps.FillUserData && <RegistrationForm/>}
                    {step === RegistrationSteps.ConfirmPhone && <PhoneConfirmation/>}
                    {step === RegistrationSteps.ConfirmEmail && <EmailConfirmation/>}
                </AuthLayout>
            </RegistrationContext.Provider>
        </AuthContainer>
    );
}
