/* eslint-disable @typescript-eslint/no-empty-function */
import React, {Dispatch, SetStateAction} from 'react';

import { PasswordRecoverySteps } from '@logic/models/Auth/AuthSteps';

export interface PasswordRecoveryContextType {
    step: PasswordRecoverySteps,
    setStep: Dispatch<SetStateAction<PasswordRecoverySteps>>,
}

const PasswordRecoveryContext = React.createContext<PasswordRecoveryContextType>({
    step: PasswordRecoverySteps.EnterEmail,
    setStep: () => {},
});

export { PasswordRecoveryContext };