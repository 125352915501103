import React, {useContext, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';
import styled, { css } from 'styled-components';

import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import LoginDataValidator from '@logic/forms/validators/validators/LoginDataValidator';
import StateUpdater from '@logic/helpers/StateUpdater/StateUpdater';
import login from '@logic/language/ex/landing/auth/login';
import { LoginSteps } from '@logic/models/Auth/AuthSteps';
import AuthData from '@logic/models/Login/AuthData';
import AuthRequest from '@logic/service/ApiRequest/RequestTypes/Auth/AuthRequest';
import ConfirmationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/ConfirmationResponse';
import {AuthResponse} from '@logic/service/ApiRequest/ResponseTypes/User/UserResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import {LandingContext} from '@context/LandingContext';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setAuthSession} from '@store/reducers/user/userSlice';

import Button from '@components/common/buttons/Button';
import LinkButton, { LinkStyle } from '@components/common/buttons/LinkButton';
import { Input } from '@components/common/inputs/BaseInput/Input';
import {Colors} from '@components/styledComponents/base/Colors';
import PasswordInput from '@src/common/inputs/PasswordInput/PasswordInput';
import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { mediaBreakpointDown, mediaBreakpointUp } from '@src/styledComponents/base/functions';
import { typography } from '@src/styledComponents/base/typography';

import {AuthModalState} from '@landing/modals/auth/AuthModal';
import {CaptchaAction} from '@landing/modals/auth/registration/ReCaptchaComponent';

type Props = {
    setLoginStep: React.Dispatch<React.SetStateAction<LoginSteps>>,
    setPhone: React.Dispatch<React.SetStateAction<string>>,
    setConfirmations: React.Dispatch<React.SetStateAction<ConfirmationResponse | null>>
};

export default function LoginForm (props: Props) {
    const {setLoginStep, setPhone} = props;

    const router = useRouter();
    const dispatch = useDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { setAuthModalState } = useContext(LandingContext);
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [validatorResult, setValidatorResult] = useState(new ValidatorResult<AuthData>());
    const [data, setData] = useState<AuthData>({
        email: ``,
        password: ``,
    });

    /**
     * Получаем функцию обновления состояния формы (key, value)
     * */
    const setFieldValue = StateUpdater
        .builder<AuthData, AuthData>(setData)
        .setOnUpdate((key) => {
            setValidatorResult((prev) => {
                prev.removeError(key);
                return prev;
            });
        })
        .build()
        .get();

    const validator = new LoginDataValidator();

    const apiService = usePaApiService(ApiService, ['email', 'password'], {
        ru: `Вход`,
        en: `Log In`,
        lt: `Prisijungti`,
        uz: `Kirish`
    });

    const getCaptchaToken = async () => {
        if (executeRecaptcha && process.env.NEXT_PUBLIC_MODE !== 'test') {
            return await executeRecaptcha(CaptchaAction.LOGIN);
        }
        return '';
    };

    const sendForm = async (e?: React.FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        const formValidatorResult = validator.validate(data);
        setValidatorResult(formValidatorResult);

        if (!formValidatorResult.isSuccess()) return;

        setButtonLoading(true);

        const token = await getCaptchaToken();

        apiService.login({...data, reCaptchaResponse: token} as AuthRequest)
            .then((response) => {
                if (!response.isSuccess()) {
                    return;
                }
                if (response.getData().tokenPair) {
                    return dispatch(setAuthSession(response.getData() as unknown as AuthResponse));
                }
                setLoginStep(LoginSteps.CHECK_CODE);
                setPhone(response.getData().user.phone);
                // setConfirmations(response.getData().confirmations.phone);
                // dispatch(setAuthSession(response.getData()));
            })
            .finally(()=> setButtonLoading(false));
    };

    // const externalLinks = [
    //     { href: '#', src: 'landing/modalAuth/buttonApple.svg', alt: 'Apple logo' },
    //     { href: '#', src: 'landing/modalAuth/buttonGoogle.svg', alt: 'Google logo' },
    //     { href: '#', src: 'landing/modalAuth/buttonFacebook.svg', alt: 'Facebook logo' }
    // ];

    return (
        <Content>
            <Form onSubmit={(e) => sendForm(e)}>
                <ToSignUpTextForMobile>
                    <Heading>
                        <Title>
                            {login.mapHeading[language]}
                        </Title>
                        <Text>
                            {login.mapText[language]}{' '}
                        </Text>
                    </Heading>
                    <LinkButton
                        as='button'
                        type='button'
                        linkStyle={LinkStyle.GRAY}
                        onClick={() => {
                            router.push(router.asPath.replace('sign-in', 'sign-up'));
                            setAuthModalState(AuthModalState.Register);
                        }}>
                        {login.mapLinkText[language]}
                    </LinkButton>
                </ToSignUpTextForMobile>
                <Fields>
                    {(validatorResult.hasError('email') || validatorResult.hasError('password')) && (
                        <ErrorMessage>
                            {validatorResult.getMessageByLanguage('email', language) === ''
                                ? validatorResult.getMessageByLanguage('password', language)
                                : validatorResult.getMessageByLanguage('email', language)}

                        </ErrorMessage>
                    )}
                    <Input
                        label={login.labelEmail[language]}
                        noMessageSpace
                        name={'email'}
                        placeholder={''}
                        value={data.email}
                        setValue={(value) => setFieldValue('email', value.replaceAll(' ', ''))}
                        isError={validatorResult.hasError('email')}
                    />
                    <PasswordContainer>
                        <PasswordInput
                            label={login.labelPassword[language]}
                            noMessageSpace
                            placeholder={''}
                            value={data.password}
                            name='password'
                            setValue={(value) => setFieldValue('password', value)}
                            isError={validatorResult.hasError('password')}
                        />
                        <ButtonContainer>
                            <MapButtonSwitchAuth
                                onClick={() => {
                                    router.push(router.asPath.replace('sign-in', 'password-reset'));
                                    setAuthModalState(AuthModalState.Recover);
                                }}>
                                {login.forgotPassword[language]}
                            </MapButtonSwitchAuth>
                        </ButtonContainer>
                    </PasswordContainer>
                </Fields>
                <LoginAction>
                    <Button
                        styles={buttonStyles}
                        type='submit'
                        disabled={buttonLoading}
                        isLoading={buttonLoading}>
                        {login.submitButtonText[language]}
                    </Button>
                </LoginAction>
                {/*<ExternalLoginButtons>*/}
                {/*    { externalLinks.map((link, index) =>*/}
                {/*        <Link href={link.href} key={index}>*/}
                {/*            <Img alt={link.alt} src={link.src}/>*/}
                {/*        </Link>*/}
                {/*    )}*/}
                {/*</ExternalLoginButtons>*/}
            </Form>
        </Content>
    );
}

const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
`;

const Form = styled.form`
    display: grid;
    grid-gap: 32px;
    align-items: flex-start;
    width: 260px;
    transition: .5s;
`;

const ToSignUpTextForMobile = styled.div`
    ${mediaBreakpointUp(Breakpoints.xMobile)} {
        display: none;
    }
`;

const Heading = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Text = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_900};
`;

const Fields = styled.div`
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        gap: 24px;
    }
`;

const LoginAction = styled.div`
    display: grid;
    justify-content: center;
`;

const buttonStyles = css`
    width: 260px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 190px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        width: 156px;
    }
`;

const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: right;
`;

const MapButtonSwitchAuth = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_500};

    cursor: pointer;
`;

// const ExternalLoginButtons = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     gap: 16px;
// `;

// const Img = styled.img`
//     height: 52px;
//     width: 52px;
//     object-fit: contain;
//
//     ${mediaBreakpointDown(Breakpoints.xTablet)} {
//         height: 42px;
//         width: 42px;
//     }
// `;

const ErrorMessage = styled.div`
    ${typography.body};
    color: ${Colors.DANGER};
`;
