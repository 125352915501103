import React from 'react';
import styled from 'styled-components';

import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';

import {useAppSelector} from '@store/hooks/hooks';

import {Colors} from '@components/styledComponents/base/Colors';
import { Typography, typography } from '@components/styledComponents/base/typography';

import ErrIcon from '@public/icons/baseUI/alert-icons/alert_icon.svg';


type Props = {
    hint?: string
    type?: string,
    fieldName: string,
    name?: string,
    placeholder?: string,
    value: string | number,
    error?: PayAssistantError | null,
    validatorResult?: ValidatorResult<any>,
    onChange: (value: string | number, fieldName?: string) => void;
    maxValue?: number,
    padding?: string,
    removeError: (fieldName: string) => void,
    font?: Typography;
};

export default function SimpleInputWidthEntryError (props: Props) {

    const {name, fieldName, error, value, placeholder, type, onChange, maxValue, validatorResult, padding, removeError, font = 'body'} = props;

    const {language} = useAppSelector(state => state.generalStatesSlice);

    const hasValidationError = () => !!error?.hasValidationError(fieldName) || !!validatorResult?.hasError(fieldName);

    const getError = () => {
        if(!fieldName) {
            return;
        }
        if(error && error.hasValidationError(fieldName as string)) {
            return error.getErrorTranslationByField(fieldName as string, language);
        }
        if(validatorResult?.hasError(fieldName)) {
            return validatorResult?.getMessage(fieldName)![language];
        }
    };

    const hideError = () => {
        if (hasValidationError()) {
            removeError(fieldName);
        }
    };

    return (
        <FieldContainer>
            {name &&
                <FieldName>
                    {name}
                </FieldName>
            }
            <InputContainer>
                <Input
                    font={font}
                    onFocus={hideError}
                    autoComplete={'off'}
                    padding={padding}
                    type={type}
                    hasValidationError={hasValidationError()}
                    value={value || ''}
                    placeholder={placeholder}
                    onChange={(e)=> {
                        if(maxValue && e.target.value.length > maxValue) {
                            return;
                        }
                        onChange(e.target.value, fieldName);
                    }}

                />
                {maxValue && <MaxInputValue className={`documents__characterCounter`}>{value ? value.toString().length : 0} / {maxValue}</MaxInputValue>}
                <ErrorContainer 
                    hasValidationError={hasValidationError()} 
                    onClick={hideError}>
                    {getError()}
                    <ErrIcon />
                </ErrorContainer>
            </InputContainer>

            {/*{*/}
            {/*    validatorResult?.hasValidationError(fieldName) &&*/}
            {/*    <FieldErrorMessage message={(validatorResult.getMessage(fieldName) as RuleMessage)[language]}/>*/}
            {/*}*/}
            {/*{*/}
            {/*    hasValidationError() && !validatorResult?.hasValidationError(fieldName) && <FieldError error={error ?? null} field={fieldName}/>*/}
            {/*}*/}
        </FieldContainer>
    );
}


const ErrorContainer = styled.div<{hasValidationError: boolean}>`

  cursor: pointer;
  transition: .5s;

  visibility: ${({hasValidationError}) => hasValidationError ? 'visible' : 'hidden'};
  opacity: ${({hasValidationError}) => hasValidationError ? '1' : '0'};
  pointer-events: ${({hasValidationError}) => hasValidationError ? 'all' : 'none'};

  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;

  backdrop-filter:  blur(3px);

  border-radius: 10px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  text-align: right;

  color: ${Colors.DANGER};
  font-size: 12px;
  line-height: 1;

  svg {
    width: 18px;
    height: 18px;
    stroke: ${Colors.DANGER};
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;


const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  //grid-template-rows: 15px 50px 15px;
  gap: 10px;
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  height: 48px;
`;

const MaxInputValue = styled.span`
  ${typography.default};
  position: absolute;
  height: max-content;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 17px;
  font-size: 10px;
  color: ${Colors.GRAY_500};
`;

const Input = styled.input<{hasValidationError: boolean, padding?: string, font: Typography}>`
  ${({font}) => typography[font]};
  height: 100%;
  width: 100%;
  padding: ${({padding}) => padding ? padding : '0 15px 0 15px'};
  border-radius: 10px;
  border: 1px solid ${({hasValidationError}) => hasValidationError ? Colors.DANGER : ' #d7d8de'};
  transition: 0.2s border;
  color: ${Colors.GRAY_800};


  &::placeholder {
    color: ${Colors.GRAY_500};
  }

  &:hover {
    border: 1px solid ${({hasValidationError}) => hasValidationError ? Colors.DANGER : Colors.GRAY_600};
  }

  &:focus {
    outline: none;
    border: 1px solid ${({hasValidationError}) => hasValidationError ? Colors.DANGER : Colors.GRAY_600};
  }
`;

const FieldName = styled.span`
  height: 15px;
  ${typography.default};
  font-size: 12px;
  color: ${Colors.GRAY_600};
`;
