import React, { useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';
import styled from 'styled-components';

import ApiService from '@logic/service/ApiService/ApiService';
import {LandingContext} from '@context/LandingContext';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {offLoading, turnLoading} from '@store/reducers/generalStates/generalStatesSlice';

import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { mediaBreakpointDown } from '@components/styledComponents/base/functions';
import CloseButton from '@src/common/fields/CloseButton/CloseButton';
import MiniPreloader from '@src/common/loaders/MiniPreloader/MiniPreloader';
import ServerError from '@src/common/messageBaners/ServerError/ServerError';
import ModalWindow from '@src/common/modals/ModalWindoiw/ModalWindow';
import SessionLoader from '@src/dataLoaders/SessionLoader';
import SignInLoader from '@src/dataLoaders/SignInLoader';
import Authorization from '@src/landing/modals/auth/Authorization';

export enum AuthModalState {
    Closed,
    Login,
    Register,
    Recover,
}

export default function AuthModal () {

    const router = useRouter();
    const dispatch = useDispatch();
    const { authModalStateIsOpen } = useContext(LandingContext);
    const { loading } = useAppSelector((state) => state.generalStatesSlice);
    const session = useAppSelector((state) => state.authSlice);
    const apiService = usePaApiService(ApiService);
    const [email, setEmail] = useState('');
    const [isServerError, setServerError] = useState<boolean>(false);
    const [tokenLoading, setTokenLoading] = useState(false);

    useEffect(()=> {
        const token = router.query.token as string;
        if (token && /sign-up/.test(router.asPath)) {
            setTokenLoading(true);
            dispatch(turnLoading());
            localStorage.setItem('INVITE_TOKEN', token);
            apiService.getEmailByToken(token)
                .then(res => {
                    if (res.isSuccess()) {
                        setEmail(res.getData().email);
                        setTokenLoading(false);
                        dispatch(offLoading());
                    }
                });
        }
    }, []);

    const onModalClose = () => {
        const signIn = router.asPath.replace(/sign-(in|up)|password-reset/, '');
        if (router.asPath.match(/sign-(in|up)|password-reset/) != null) {
            router.push(signIn);
        }
    };

    if (tokenLoading) {
        return <></>;
    }

    return (
        <SignInLoader>
            <SessionLoader onLoadingError={() => setServerError(true)}>
                <ModalWindow
                    isOpen={authModalStateIsOpen}
                    onClose={onModalClose}>
                    <AuthContainer>
                        <CloseContainer>
                            <CloseButton onClick={() => onModalClose()}/>
                        </CloseContainer>
                        {!isServerError && session.loaded && <Authorization email={email}/>}
                        {(loading || !session.loaded && !isServerError) && <MiniPreloader/>}
                        {isServerError && <ServerError/>}
                    </AuthContainer>
                </ModalWindow>
            </SessionLoader>
        </SignInLoader>
    );

}

const AuthContainer = styled.div`
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    margin: auto;
    background: #ffffff;
    width: 100%;
    height: 100%;
    max-height: 700px;
    max-width: 1000px;
    overflow: hidden;

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        max-height: 600px;
        padding: 24px 0;
    }
`;

const CloseContainer = styled.div`
    z-index: 1;
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        top: 20px;
        right: 20px;
    }
`;
