import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import ym, {YMInitializer} from 'react-yandex-metrika';
import Head from 'next/head';
import {Router, useRouter} from 'next/router';
import styled from 'styled-components';

import SiteType from '@logic/enums/TypeSite';
import RuleMessage from "@logic/forms/validators/rules/RuleMessage";
import {LandingContext} from '@context/LandingContext';

import {useAppSelector} from '@store/hooks/hooks';

import AuthModal, {AuthModalState} from '@src/landing/modals/auth/AuthModal';

import ApplicationFormModal from '@landing/components/ApplicationForm/ApplicationFormModal';


type Props = {
    children?: ReactNode;
    title?: string;
    content?: string,
};

export default function LandingLayout (props: Props) {

    const {
        content,
        children,
        title = `pay-assistant`} = props;
    const {site} = useAppSelector(state => state.generalStatesSlice);
    const router = useRouter();

    const [authModalState, setAuthModalState] = useState<AuthModalState>(AuthModalState.Closed);
    const [authModalStateIsOpen, setAuthModalStateIsOpen] = useState<boolean>(false);

    const [applicationFormOptions, setApplicationFormOptions] = useState<{ isOpen: boolean, title?: RuleMessage }>({
        isOpen: false,
    });

    const getUrl = () => {
        if(/sign-up/.test(router.asPath)) {
            setAuthModalStateIsOpen(true);
            return AuthModalState.Register;
        }
        if(/sign-in/.test(router.asPath)) { // /sign-(in|up)/
            setAuthModalStateIsOpen(true);
            return AuthModalState.Login;
        }
        if(/password-reset/.test(router.asPath)) {
            setAuthModalStateIsOpen(true);
            return AuthModalState.Recover;
        }
        setAuthModalStateIsOpen(false);
        return authModalState;
    };

    useEffect(()=>{
        setAuthModalState(getUrl());
        return () => setAuthModalState(AuthModalState.Login);
    }, [router]);

    const hit = useCallback((url: string) => {
        ym('hit', url);
    }, []);

    useEffect(() => {
        if(site === 'ru') {
            hit(window.location.pathname + window.location.search);
            Router.events.on('routeChangeComplete', (url: string) => hit(url));
        }
    }, []);


    return (
        <>
            <Head>
                <title>{title ? title : `Pay-Assistant`}</title>
                <meta name="description" content={content ? content : `pay-assistant`}/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui, user-scalable=no"/>
            </Head>

            <LandingContext.Provider value={{
                authModalState,
                setAuthModalState,
                authModalStateIsOpen: authModalStateIsOpen && site === SiteType.COM,
                applicationFormOptions,
                setApplicationFormOptions
            }}>
                {process.env.NEXT_PUBLIC_SITE === 'ru' && (
                    <YMInitializer
                        accounts={[Number(83871136)]}
                        options={{ webvisor: true, clickmap:true, accurateTrackBounce:true, trackLinks:true, defer: true }}
                        version="2"/>)}
                <AuthModal />
                <ApplicationFormModal isOpen={applicationFormOptions} setIsOpen={setApplicationFormOptions}/>
                <Main>
                    {children}
                </Main>
            </LandingContext.Provider>
        </>
    );
}


const Main = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
`;

