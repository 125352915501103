import IRule from '@logic/forms/validators/rules/IRule';
import MaxLengthRule from '@logic/forms/validators/rules/MaxLengthRule';
import MinLengthRule from '@logic/forms/validators/rules/MinLengthRule';
import PhoneRule from '@logic/forms/validators/rules/PhoneRule';
import RequiredRule from '@logic/forms/validators/rules/RequiredRule';
import AbstractValidator from '@logic/forms/validators/validators/AbstractValidator';
import {FormData} from '@logic/models/FormData/FormData';

export default class ApplicationFormValidator extends AbstractValidator<FormData>
{
    protected rules () : {[key in keyof FormData]?: IRule[]}
    {
        return {
            name: [new RequiredRule(), new MinLengthRule(2), new MaxLengthRule(25)],
            phone: [new PhoneRule()],
        };
    }
}