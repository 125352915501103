import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import passwordRecovery from '@logic/language/ex/landing/auth/passwordRecovery';
import { PasswordRecoverySteps } from '@logic/models/Auth/AuthSteps';
import { RecoverType } from '@logic/models/Auth/RecoverType';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { IconPosition } from '@components/common/buttons/buttonStyles';
import { Input } from '@components/common/inputs/BaseInput/Input';
import AnyError from '@src/common/errors/AnyError/AnyError';
import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { Colors } from '@src/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@src/styledComponents/base/functions';
import { typography } from '@src/styledComponents/base/typography';

import ArrIcon from '@public/icons/baseUI/arrow-icons/long_arrow.svg';

import { PasswordRecoveryContext } from '@landing/modals/auth/passwordRecovery/PasswordRecoveryContext';

type Props = {
    setEmail: React.Dispatch<React.SetStateAction<string>>,
    email: string,
    setRecoverType: React.Dispatch<React.SetStateAction<RecoverType>>,
    errorMessageResponse: PayAssistantError | null
};

export default function EmailForm (props: Props) {
    const { email, setEmail, setRecoverType, errorMessageResponse } = props;

    const { setStep } = useContext(PasswordRecoveryContext);
    const api = usePaApiService(ApiService);
    const { language } = useAppSelector(state => state.generalStatesSlice);
    const [errorResponse, setErrorResponse] = useState<PayAssistantError | null>(null);
    const [loading, setLoading] = useState(false);

    const resetPassword: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        setLoading(true);

        api.resetPassword (email).then((response)=>{
            if(!response.isSuccess()) {
                setErrorResponse(ApiErrorAdapter.getPayAssistantError(response.getError()));
            } else {
                setRecoverType(RecoverType.WAITING);
                setStep(PasswordRecoverySteps.VerifyCode);
            }
        }).finally(()=>{
            setLoading(false);
        });
    };

    return (
        <Content>
            <Form onSubmit={resetPassword}>
                <Heading>
                    <Title>
                        {passwordRecovery.enterEmailHeading[language]}
                    </Title>
                    <Text>
                        {passwordRecovery.enterEmailMessage[language]}
                    </Text>
                </Heading>
                <ResetPasswordForm>
                    <Input
                        name='email'
                        placeholder={passwordRecovery.labelEmail[language]}
                        value={email}
                        setValue={(value) => setEmail(value)}
                        noMessageSpace
                    />
                    <AnyError error={errorResponse}/>
                </ResetPasswordForm>
                <ButtonContainer>
                    <Button
                        styles={buttonStyles}
                        type='submit'
                        isLoading={loading}
                        Icon={<ArrIcon />}
                        iconPosition={IconPosition.RIGHT}>
                        {passwordRecovery.enterEmailButton[language]}
                    </Button>
                    <AnyError error={errorMessageResponse}/>
                </ButtonContainer>
            </Form>
        </Content>
    );
}

const Content = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
`;

const Form = styled.form`
    width: 260px;
    display: grid;
    align-items: flex-start;
    gap: 16px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        gap: 32px;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
`;

const buttonStyles = css`
    width: 260px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 190px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        width: 156px;
    }
`;

const Heading = styled.div`
    width: 100%;
    display: grid;
    gap: 8px;
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Text = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_900};
`;

const ResetPasswordForm = styled.div`
    width: 100%;
`;
