import {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import registration from '@logic/language/ex/landing/auth/registration';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setPhoneConfirmation} from '@store/reducers/auth/authSlice';

import ConfirmationAuthLayout from '@src/landing/modals/auth/ConfirmationAuthLayout';
import {RegistrationContext} from '@src/landing/modals/auth/registration/RegistrationContext';

export default function PhoneConfirmation () {

    const dispatch = useDispatch();
    const apiService = usePaApiService(ApiService);
    const {language} = useAppSelector((state) => state.generalStatesSlice);
    const session = useAppSelector((state) => state.authSlice);
    const {registrationData} = useContext(RegistrationContext);
    const [sendLoading, setSetLoading] = useState(true);

    const sendCode = async (): Promise<{resendRemain: number, isSuccess: boolean, error: PayAssistantError | null}> => apiService.sendPhoneCode().then(response => {
        if (!response.isSuccess()) {
            return {resendRemain: 0, isSuccess: false, error: ApiErrorAdapter.getPayAssistantError(response.getError())};
        }
        return {resendRemain: response.getData().resendRemain, isSuccess: true, error: null};
    }).finally(() => {
        setSetLoading(false);
    });

    const getSessionState = (): {needSend: boolean, resendRemain: number} => {
        const isConfirmed = session.data.registration?.confirmations.phone.isConfirmed;
        const isAlive = session.data.registration?.confirmations.phone.isAlive;
        const canResend = session.data.registration?.confirmations.phone.canResend;
        const resendRemain = session!.data!.registration!.confirmations.phone.resendRemain;
        if (!isAlive && !isConfirmed) {
            return {needSend: true, resendRemain: 90};
        }
        return {needSend: !!canResend, resendRemain: resendRemain};
    };

    //
    // useEffect(() => {
    //     if (session?.data?.login?.authorized) return;
    //
    //     if (!session.data.registration?.confirmations.phone.isAlive
    //         && !session.data.registration?.confirmations.phone.isConfirmed) {
    //         sendCode();
    //     } else {
    //         if (session.data.registration.confirmations.phone.resendRemain > 0) {
    //             setTime(session.data.registration.confirmations.phone.resendRemain);
    //         }
    //         setSetLoading(false);
    //     }

    //     return () => {
    //         setTime(0);
    //         setError(null);
    //     };
    // }, [session]);

    const checkCode = async (code: string): Promise<PayAssistantError | null> => {
        const response = await apiService.checkPhoneCode({code});
        if (!response.isSuccess()) {
            return ApiErrorAdapter.getPayAssistantError(response.getError());
        }
        dispatch(setPhoneConfirmation(response.getData()));
        return null;
    };

    return (
        <ConfirmationAuthLayout
            description={`
                        ${registration.phoneConfirmationTextBefore[language]}
                        ${registrationData.phone.number}
                        ${registration.phoneConfirmationTextAfter[language]}
            `}
            buttonText={registration.buttonNext[language]}
            getSessionState={getSessionState}
            sendCode={sendCode}
            checkCode={checkCode}
            sendLoading={sendLoading}
        />
    );
}