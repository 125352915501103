import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

import checkPhone from 'google-libphonenumber';
const phoneUtil = checkPhone.PhoneNumberUtil.getInstance();


export default class EmailRule implements IRule
{

    public check (value : any) : boolean
    {
        const emailRegexp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-])+\.([A-Za-z]{2,15})$/;
        return emailRegexp.test(value);
    }

    public message () : RuleMessage
    {
        return {
            ru: `Неверный формат email`,
            en: `The field is filled in incorrectly!`,
            lt: `Laukas užpildytas neteisingai!`,
            uz: `Noto'g'ri elektron pochta formati`
        };
    }
}

