const applicationForm = {
    title: {
        ru: 'Оставьте заявку',
        en: 'Ask a question',
        uz: 'Savolingizni so\'rang',
        lt: ''
    },
    underTitle: {
        ru: 'Чтобы получить самый выгодный тариф для вашего бизнеса',
        en: 'Write your question and we will answer you as soon as possible!',
        uz: 'Savolingizni yozing va bizning menejer sizga tez orada javob beradi!',
        lt: ''
    },
    applicationSuccessTitle: {
        ru: 'Спасибо за заявку!',
        en: 'Thank you for your application!',
        uz: 'Murojaat uchun rahmat!',
        lt: ''
    },
    goBack: {
        ru: 'Вернуться на главную',
        en: 'Go back to the main page',
        uz: 'Asosiy sahifaga qaytish',
        lt: ''
    },
    duty: {
        ru: 'Режим работы(+3 МСК)',
        en: 'Work mode(+3 MSK)',
        uz: 'Ish rejimi(+3 MSK))',
        lt: ''
    },
    mnFr: {
        ru: 'Пн-Пт',
        en: 'Mon-Fri',
        uz: 'Dushanba-Payshanba',
        lt: ''
    },
    applicationSuccess:{
        ru: 'Наш менеджер уже обрабатывает ее и скоро свяжется с вами.',
        en: 'Our manager is already processing it and will contact you soon.',
        uz: 'Bizning menejerimiz allaqachon uni qaytadan ishlamoqda va tez orada siz bilan bog\'lanadi.',
        lt: ''
    },
    name: {
        ru: 'Имя',
        en: 'Name',
        uz: 'Ism',
        lt: ''
    },
    phone: {
        ru: 'Телефон',
        en: 'Phone',
        uz: 'Telefon',
        lt: ''
    },
    question: {
        ru: 'Вопрос',
        en: 'Question',
        uz: 'Savol',
        lt: ''
    },
    button: {
        ru: 'Оставить заявку',
        en: 'Submit',
        uz: 'Murojaat qoldirish',
        lt: ''
    },
    personalDataText: {
        ru: 'Нажимая на кнопку, вы даете согласие на обработку {}',
        en: 'By clicking the button, you agree to the processing of {}',
        uz: 'Tugmani bosish orqali, {} ishlashga rozilik berishingiz mumkin',
        lt: ''
    },
    personalDataLink: {
        ru: 'персональных данных',
        en: 'personal data',
        uz: 'shaxsiy ma\'lumotlarni',
        lt: ''
    }

};

export default applicationForm;