const login = {
    mapHeading: {
        ru: `Вход`,
        en: `Log in`,
        lt: `Prisijungti`,
        uz: `Kirish`
    },
    mapText: {
        ru: `У вас нет аккаунта?`,
        en: `Don’t have an account?`,
        lt: `Jūs neturite paskyros?`,
        uz: `Sizda hisob yo'qmi?`
    },
    mapLinkText: {
        ru: `Зарегистрироваться`,
        en: `Sign up`,
        lt: `Užsiregistruoti`,
        uz: `Ro'yxatdan o'tish`
    },
    labelBackButton: {
        ru: `Назад`,
        en: `Back`,
        lt: `Atgal `,
        uz: `Orqaga`,
    },
    labelEmail: {
        ru: `Почта`,
        en: `E-mail`,
        lt: `Paštas`,
        uz: `Elektron pochta`,
    },
    labelPassword: {
        ru: `Пароль`,
        en: `Password`,
        lt: `Slaptažodis`,
        uz: `Parol`,
    },
    submitButtonText: {
        ru: `Вход`,
        en: `Log in`,
        lt: `Prisijungti`,
        uz: `Kirish`
    },
    forgotPassword: {
        ru: `Восстановить пароль`,
        en: `Forgot password?`,
        lt: `Atkurti slaptažodį`,
        uz: `Parolni qayta tiklash`
    },
};

export default login;
