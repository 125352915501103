/* eslint-disable @typescript-eslint/no-empty-function */
import React, {Dispatch, SetStateAction} from 'react';

import {RegistrationSteps} from '@logic/models/Auth/AuthSteps';
import {RegistrationData} from '@logic/models/Auth/RegistrationData';
import {UserRole} from "@logic/models/Auth/UserRole";

export interface RegistrationContextType {
    registrationData: RegistrationData;
    setRegistrationData: Dispatch<SetStateAction<RegistrationData>>,
    step: RegistrationSteps,
    setStep: Dispatch<SetStateAction<RegistrationSteps>>,
    predefinedEmail: string | null
}
const RegistrationContext = React.createContext<RegistrationContextType>({
    registrationData: {
        role: UserRole.NotSelected,
        firstName: '',
        lastName: '',
        email: '',
        phone: {
            number: '',
            country: ''
        },
        password: ``,
        repeatPassword: ``,
        agreeWithTerms: false
    },
    setRegistrationData: () => {},
    step: RegistrationSteps.SelectRole,
    setStep: () => {},
    predefinedEmail: null
});

export {RegistrationContext};