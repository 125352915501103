const passwordRecovery = {
    mapHeading: {
        ru: `Восстановление`,
        en: `Password recovering`,
        lt: `Atkūrimas`,
        uz: `Qayta tiklash`,
    },
    mapText: {
        ru: `Вспомнили пароль?`,
        en: `Knew the password?`,
        lt: `Prisimenate slaptažodį?`,
        uz: `Parolni eslaysizmi?`
    },
    enterEmailHeading: {
        ru: `Восстановление пароля`,
        en: `Enter the e\u2011mail`,
        lt: `Slaptažodžio atkūrimas`,
        uz: `Parolni tiklash`
    },
    enterEmailMessage: {
        ru: `Введите почту, к которой приявязан аккаунт`,
        en: `Enter the e-mail address to which the account is linked`,
        lt: `Įveskite el. paštą, prie kurio prijungta paskyra`,
        uz: `Hisob qaydnomasi bog'langan pochtani kiriting`
    },
    labelEmail: {
        ru: `Почта`,
        en: `E-mail`,
        lt: `Paštas`,
        uz: `Elektron pochta`,
    },
    labelPassword: {
        ru: `Пароль`,
        en: `Password`,
        lt: `Slaptažodis`,
        uz: `Parol`,
    },
    enterEmailButton: {
        ru: `Отправить письмо`,
        en: `Submit`,
        lt: `Siųsti laišką`,
        uz: `Xat yuboring`
    },
    verifyCodeHeading: {
        ru: `Введите код подтвержения`,
        en: `Verify code`,
        lt: `Įveskite patvirtinimo kodą`,
        uz: `Tasdiqlash kodini kiriting`
    },
    verifyCodeMessage: {
        ru: `На почту {0} придет письмо с ссылкой для сброса пароля`,
        en: `An email with a link to reset your password was sent to the e-mail {0}`,
        lt: `Į paštą {0} ateis laiškas su nuoroda slaptažodžiui iš naujo nustatyti`,
        uz: `Pochta {0} parolni tiklash havolasi bilan xat keladi`
    },
    verifyCodeButton: {
        ru: `На главную`,
        en: `On main page`,
        lt: `Į pagrindinį`,
        uz: `Asosiy`
    },
    resetPasswordHeading: {
        ru: `Придумайте новый пароль`,
        en: `Create new password`,
        lt: `Sugalvokite naują slaptažodį`,
        uz: `Yangi parol bilan chiqing`
    },
    resetPasswordMessage: {
        ru: `Допускаются только буквы латинского алфавита (A-Z, a-z), цифры (0-9) и символы: !@#$%^&*()_=+[]{};:|,`,
        en: `Use only letters from the Latin alphabet (A-Z, a-z), numerals (0-9) and symbols: !@#$%^&*()_=+[]{};:|,`,
        lt: `Naudokite tik raides iš lotyniškos abėcėlės (A-Z, a-z), skaitmenis (0-9) ir simbolius: !@#$%^&*()_=+[]{};:|,`,
        uz: `Lotin alifbosidagi harflar (A-Z, a-z), raqamlar (0-9) va belgilar: !@#$%^&*()_=+[]{};:|, dan foydalaning`,
    },
    resetPasswordButton: {
        ru: `Изменить пароль`,
        en: `Submit`,
        lt: `Keisti slaptažodį`,
        uz: `Parolni o'zgartirish`
    },
};

export default passwordRecovery;
