import com from '@logic/language/com/landing/index/applicationForm';
import ru from '@logic/language/ru/landing/index/applicationForm';
import uz from '@logic/language/uz/landing/index/applicationForm';


export default {
    com,
    uz,
    ru
};
