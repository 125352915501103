import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import passwordRecovery from '@logic/language/com/landing/auth/passwordRecovery';
import login from '@logic/language/ex/landing/auth/login';
import { PasswordRecoverySteps } from '@logic/models/Auth/AuthSteps';
import { RecoverType } from '@logic/models/Auth/RecoverType';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import { LandingContext } from '@context/LandingContext';
import { usePaApiService } from '@hooks/usePaApiService';

import { useAppSelector } from '@store/hooks/hooks';

import MiniPreloader from '@src/common/loaders/MiniPreloader/MiniPreloader';

import AuthContainer from '@landing/modals/auth/AuthContainer';
import { AuthModalState } from '@landing/modals/auth/AuthModal';
import EmailForm from '@landing/modals/auth/passwordRecovery/EmailForm';
import NewPasswordForm from '@landing/modals/auth/passwordRecovery/NewPasswordForm';
import { PasswordRecoveryContext } from '@landing/modals/auth/passwordRecovery/PasswordRecoveryContext';
import WaitingMessage from '@landing/modals/auth/passwordRecovery/WaitingMessage';

import AuthLayout from '../AuthLayout';

type Props = {
    isOpen: boolean;
};

export default function PasswordRecovery (props: Props) {
    const { isOpen } = props;

    const router = useRouter();
    const api = usePaApiService(ApiService);
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const { setAuthModalState} = useContext(LandingContext);
    const [recoverType, setRecoverType] = useState<RecoverType>(RecoverType.LOADING);
    const [email, setEmail] = useState('');
    const [step, setStep] = useState<PasswordRecoverySteps>(PasswordRecoverySteps.EnterEmail);
    const [token, setToken] = useState('');
    const [errorResponse, setErrorResponse] = useState<PayAssistantError | null>(null);

    useEffect(()=> {
        const token = router.query.token;
        const role = router.query.role;
        const isPasswordReset = /password-reset/.test(router.asPath);

        if (token && typeof token === 'string' && !role && isPasswordReset) {
            api.resetToken(token)
                .then((response => {
                    if(response.isSuccess()) {
                        setEmail(response.getData().email);
                        setToken(token);
                        setRecoverType(RecoverType.RESET);
                        setStep(PasswordRecoverySteps.ResetPassword);
                    } else {
                        setErrorResponse(ApiErrorAdapter.getPayAssistantError(response.getError()));
                        setRecoverType(RecoverType.EMAIL);
                    }
                }));
        } else {
            setRecoverType(RecoverType.EMAIL);
        }
    }, []);

    const passwordRecoverySteps = [
        { step: PasswordRecoverySteps.EnterEmail, text: passwordRecovery.enterEmailHeading[language] },
        { step: PasswordRecoverySteps.VerifyCode, text: passwordRecovery.verifyCodeHeading[language] },
        { step: PasswordRecoverySteps.ResetPassword, text: passwordRecovery.resetPasswordHeading[language] },
    ];

    const data = {
        sidebarTitle: passwordRecovery.mapHeading[language],
        sidebarText: passwordRecovery.mapText[language],
        sidebarLinkText: login.mapHeading[language],
    };

    return (
        <AuthContainer isOpen={isOpen}>
            <PasswordRecoveryContext.Provider value={{ step, setStep }}>
                <AuthLayout
                    context={PasswordRecoveryContext}
                    steps={passwordRecoverySteps}
                    data={data}
                    onClick={() => {
                        router.push(router.asPath.replace('password-reset', 'sign-in'));
                        setAuthModalState(AuthModalState.Login);
                    }}
                >
                    {recoverType === RecoverType.LOADING && <MiniPreloader/>}
                    {step === PasswordRecoverySteps.EnterEmail && <EmailForm setEmail={setEmail} email={email} setRecoverType={setRecoverType} errorMessageResponse={errorResponse}/>}
                    {step === PasswordRecoverySteps.VerifyCode && <WaitingMessage email={email}/>}
                    {step === PasswordRecoverySteps.ResetPassword && <NewPasswordForm token={token} email={email}/>}
                </AuthLayout>
            </PasswordRecoveryContext.Provider>
        </AuthContainer>
    );
}
