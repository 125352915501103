import {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';

import registration from '@logic/language/ex/landing/auth/registration';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';
import {setEmailConfirmation} from '@store/reducers/auth/authSlice';

import ConfirmationAuthLayout from '@src/landing/modals/auth/ConfirmationAuthLayout';
import {RegistrationContext} from '@src/landing/modals/auth/registration/RegistrationContext';

export default function EmailConfirmation () {

    const dispatch = useDispatch();
    const apiService = usePaApiService(ApiService);
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const session = useAppSelector((state) => state.authSlice);
    const { registrationData } = useContext(RegistrationContext);
    const [sendLoading, setSetLoading] = useState(true);

    const sendCode = async (): Promise<{resendRemain: number, isSuccess: boolean, error: PayAssistantError | null}> => apiService.sendEmailCode().then(response => {
        if (!response.isSuccess()) {
            return {resendRemain: 0, isSuccess: false, error: ApiErrorAdapter.getPayAssistantError(response.getError())};
        }
        return {resendRemain: response.getData().resendRemain, isSuccess: true, error: null};
    }).finally(() => {
        setSetLoading(false);
    });

    const getSessionState = (): {needSend: boolean, resendRemain: number} => {
        const isConfirmed = session.data.registration?.confirmations.email.isConfirmed;
        const canResend = session.data.registration?.confirmations.email.canResend;
        const isAlive = session.data.registration?.confirmations.email.isAlive;
        const resendRemain = session!.data!.registration!.confirmations.email.resendRemain;
        if (!isAlive && !isConfirmed) {
            return {needSend: true, resendRemain: 90};
        }
        return {needSend: !!canResend, resendRemain: resendRemain};
    };

    const checkCode = async (code: string): Promise<PayAssistantError | null> => {
        const response = await apiService.checkEmailCode({code});
        if (!response.isSuccess()) {
            return ApiErrorAdapter.getPayAssistantError(response.getError());
        }
        dispatch(setEmailConfirmation(response.getData()));
        return null;
    };

    return (
        <ConfirmationAuthLayout
            getSessionState={getSessionState}
            buttonText={registration.buttonNext[language]}
            description={`
                    ${registration.emailConfirmationTextBefore[language]}
                    ${registrationData.email}
                    ${registration.emailConfirmationTextAfter[language]}
            `}
            sendCode={sendCode}
            sendLoading={sendLoading}
            checkCode={checkCode}
        />
    );
}