import IRule from '@logic/forms/validators/rules/IRule';
import RequiredRule from '@logic/forms/validators/rules/RequiredRule';
import AbstractValidator from '@logic/forms/validators/validators/AbstractValidator';
import AuthData from '@logic/models/Login/AuthData';

export default class LoginDataValidator extends AbstractValidator<AuthData>
{
    protected rules () : {[key in keyof AuthData]?: IRule[]}
    {
        return {
            email: [new RequiredRule()],
            password: [new RequiredRule()],
        };
    }
}