import CheckedRule from '@logic/forms/validators/rules/CheckedRule';
import EmailRule from '@logic/forms/validators/rules/EmailRule';
import IRule from '@logic/forms/validators/rules/IRule';
import MaxLengthRule from '@logic/forms/validators/rules/MaxLengthRule';
import MinLengthRule from '@logic/forms/validators/rules/MinLengthRule';
import NoWhiteSpaceRule from '@logic/forms/validators/rules/NoWhiteSpaceRule';
import PhoneRule from '@logic/forms/validators/rules/PhoneRule';
import RequiredRule from '@logic/forms/validators/rules/RequiredRule';
import AbstractValidator from '@logic/forms/validators/validators/AbstractValidator';
import {RegistrationData} from '@logic/models/Auth/RegistrationData';

export default class RegistrationDataValidator extends AbstractValidator<RegistrationData>
{
    protected rules () : {[key in keyof RegistrationData]?: IRule[]}
    {
        return {
            firstName: [new RequiredRule(), new MinLengthRule(2), new MaxLengthRule(250), new NoWhiteSpaceRule()],
            lastName: [new RequiredRule(), new MinLengthRule(2), new MaxLengthRule(250), new NoWhiteSpaceRule()],
            phone: [new PhoneRule()],
            email: [new RequiredRule(), new EmailRule()],
            password: [new RequiredRule(), new NoWhiteSpaceRule()],
            agreeWithTerms: [new CheckedRule()]
        };
    }
}

