
import {useCallback, useEffect} from 'react';
import {
    GoogleReCaptcha,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';


export enum CaptchaAction {
    REGISTER = 'register',
    LOGIN = 'login',
}
export const YourReCaptchaComponent = ({setToken, action, reload}:{setToken: (value: string) => void, action: CaptchaAction, reload: boolean}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha || process.env.NEXT_PUBLIC_MODE === 'test') {
            return;
        }

        executeRecaptcha(action).then((token) => {
            setToken(token);
        }).catch((error) => {
            console.log(error);
        });

    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify, reload]);

    return <GoogleReCaptcha onVerify={handleReCaptchaVerify} />;
};