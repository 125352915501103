import React, {useContext} from 'react';
import {useRouter} from 'next/router';
import styled, { css } from 'styled-components';

import passwordRecovery from '@logic/language/ex/landing/auth/passwordRecovery';
import {LandingContext} from '@context/LandingContext';

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import {AuthModalState} from '@src/landing/modals/auth/AuthModal';
import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { Colors } from '@src/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@src/styledComponents/base/functions';
import { typography } from '@src/styledComponents/base/typography';

type Props = {
    email: string,
};

export default function WaitingMessage (props: Props) {
    const { email } = props;

    const router = useRouter();
    const { setAuthModalState } = useContext(LandingContext);
    const { language} = useAppSelector(state => state.generalStatesSlice);

    return (
        <Content>
            <Form>
                <Heading>
                    <Title>
                        {passwordRecovery.verifyCodeHeading[language]}
                    </Title>
                    <Text>
                        {passwordRecovery.verifyCodeMessage[language].format(email)}
                    </Text>
                </Heading>
                <ButtonContainer>
                    <Button
                        styles={buttonStyles}
                        onClick={()=> {
                            router.push('/').then(() => setAuthModalState(AuthModalState.Closed));
                        }}>
                        {passwordRecovery.verifyCodeButton[language]}
                    </Button>
                </ButtonContainer>
            </Form>
        </Content>
    );
}

const Content = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
`;

const Form = styled.form`
    width: 260px;
    display: grid;
    align-items: flex-start;
    gap: 16px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        gap: 32px;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
`;

const buttonStyles = css`
    width: 260px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 190px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        width: 156px;
    }
`;

const Heading = styled.div`
    width: 100%;
    display: grid;
    gap: 8px;
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Text = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_900};
`;
