import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import login from '@logic/language/ex/landing/auth/login';
import { LoginSteps } from '@logic/models/Auth/AuthSteps';
import ConfirmationResponse from '@logic/service/ApiRequest/ResponseTypes/Auth/ConfirmationResponse';
import ApiService from '@logic/service/ApiService/ApiService';
import { LandingContext } from '@context/LandingContext';
import { usePaApiService } from '@hooks/usePaApiService';

import { useAppSelector } from '@store/hooks/hooks';

import MiniPreloader from '@src/common/loaders/MiniPreloader/MiniPreloader';

import AuthContainer from '@landing/modals/auth/AuthContainer';
import { AuthModalState } from '@landing/modals/auth/AuthModal';
import CodeForm from '@landing/modals/auth/login/CodeForm';
import LoginForm from '@landing/modals/auth/login/LoginForm';
import { RegistrationContext } from '@landing/modals/auth/registration/RegistrationContext';

import AuthLayout from '../AuthLayout';

type Props = {
    isOpen: boolean;
};

export default function Login (props: Props) {
    const { isOpen } = props;

    const router = useRouter();
    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const { setAuthModalState } = useContext(LandingContext);
    const apiService = usePaApiService(ApiService);
    const [loginStep, setLoginStep] = useState<LoginSteps>(LoginSteps.SEND_CREDENTIALS);
    const [confirmations, setConfirmations] = useState<ConfirmationResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [phone, setPhone] = useState('');

    useEffect(()=>{
        apiService.getLoginSession()
            .then((res) => {
                if(res.isSuccess()) {
                    const data = res.getData();
                    if(data.isFilled) {
                        setConfirmations(data.confirmations.phone);
                        setLoginStep(LoginSteps.CHECK_CODE);
                        setPhone(data.user.phone);
                    }
                }
            })
            .finally(()=>{
                setIsLoading(false);
            });
    }, []);

    const data = {
        sidebarTitle: login.mapHeading[language],
        sidebarText: login.mapText[language],
        sidebarLinkText: login.mapLinkText[language],
    };

    return (
        <AuthContainer isOpen={isOpen}>
            <AuthLayout
                context={RegistrationContext}
                data={data}
                onClick={() => {
                    router.push(router.asPath.replace('sign-in', 'sign-up'));
                    setAuthModalState(AuthModalState.Register);
                }}
            />
            {isLoading && <MiniPreloader/>}
            {loginStep === LoginSteps.SEND_CREDENTIALS && <LoginForm setLoginStep={setLoginStep} setPhone={setPhone} setConfirmations={setConfirmations}/>}
            {!isLoading && loginStep !== LoginSteps.SEND_CREDENTIALS && <CodeForm confirmations={confirmations} setLoginStep={setLoginStep} phone={phone}/>}
        </AuthContainer>
    );
}
