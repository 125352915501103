import styled from 'styled-components';

import applicationForm from '@logic/language/ex/landing/index/applicationForm';
import useLanguage from '@hooks/useLanguage';

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { Colors } from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

export default function AppealSuccess ({onClose}: {onClose: () => void}) {

    const texts = useLanguage(applicationForm);
    const {language} = useAppSelector(state => state.generalStatesSlice);

    return (
        <Container>
            <SuccessIcon/>
            <TextContainer>
                <AppealSuccessTitle>
                    {texts.applicationSuccessTitle[language]}
                </AppealSuccessTitle>
                <AppealSuccessParagraph>
                    {texts.applicationSuccess[language]}
                </AppealSuccessParagraph>
                <Button 
                    data-test-id="goBackApplicationModalButton"
                    isWide
                    onClick={onClose}>
                    {texts.goBack[language]}
                </Button>
            </TextContainer>
            <DutyContainer>
                <DutyText>
                    {texts.duty[language]}
                </DutyText>
                <DutyText>
                    {texts.mnFr[language]} 9:00-19:00
                </DutyText>
            </DutyContainer>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const SuccessIcon = styled.div`
  height: 250px;
  width: 250px;
  background: url('/landing/appealForm/appealSuccess.svg') no-repeat center center;
  background-size: contain;
`;

const TextContainer = styled.div`
    display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const AppealSuccessTitle = styled.span`
  ${typography.body};
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: ${Colors.GRAY_900};
  
  text-align: center;
`;

const AppealSuccessParagraph = styled.span`
    ${typography.body};
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${Colors.GRAY_900};
`;

const DutyContainer = styled.div`
    display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const DutyText = styled.span`
  ${typography.body};
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #848E92;
`;
