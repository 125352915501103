import {Dispatch, FormEvent, SetStateAction, useState} from 'react';
import styled from 'styled-components';

import SiteType from '@logic/enums/TypeSite';
import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import RuleMessage from "@logic/forms/validators/rules/RuleMessage";
import ApplicationFormValidator from '@logic/forms/validators/validators/ApplicationFormValidator';
import MetricHelper from '@logic/helpers/MetricHelper';
import applicationForm from '@logic/language/ex/landing/index/applicationForm';
import labels from '@logic/language/ex/landing/labels/labels';
import {AppealFormDataClient} from '@logic/models/FormData/FormData';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import AppealRequest from '@logic/service/ApiRequest/RequestTypes/AppealRequest/AppealRequest';
import ApiService from '@logic/service/ApiService/ApiService';
import useDataStateUpdater from '@hooks/useDataStateUpdater';
import useLanguage from '@hooks/useLanguage';
import {usePaApiService} from "@hooks/usePaApiService";

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import LinkButton from '@components/common/buttons/LinkButton';
import { Input } from '@components/common/inputs/BaseInput/Input';
import { Heading2 } from '@components/common/texts/typography/heading';
import { Colors } from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';
import PhoneInputWidthEntryError from '@src/common/inputs/PhoneInput/PhoneInputWidthEntryError';
import SimpleInputWidthEntryError from '@src/common/inputs/SImpleInput/SimpleInputWidthEntryError';
import Spinner from '@src/common/loaders/Spinner/Spinner';
import ModalWindow from '@src/common/modals/ModalWindoiw/ModalWindow';

import AppealSuccess from '@landing/components/ApplicationForm/AppealSuccess';


type Props = {
    isOpen: { isOpen: boolean, title?: RuleMessage };
    setIsOpen: Dispatch<SetStateAction<{ isOpen: boolean, title?: RuleMessage }>>;

};

const CloseIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L13 13" stroke={Colors.GRAY_600} strokeWidth="2" strokeLinecap="round"/>
        <path d="M13 1L1 13" stroke={Colors.GRAY_600} strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

const formInitData: AppealFormDataClient = {
    name: ``,
    phone: {
        number: '',
        country: ''
    },
    email: ``,
    question: ``
};
export default function ApplicationFormModal (props: Props) {
    const {isOpen, setIsOpen} = props;

    const texts = useLanguage(applicationForm);
    const {site, language} = useAppSelector((state) => state.generalStatesSlice);

    const validator = new ApplicationFormValidator();
    const [validatorResult, setValidatorResult] = useState(new ValidatorResult<AppealFormDataClient>());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<PayAssistantError | null>(null);
    const apiService = usePaApiService(ApiService, [], {
        ru: 'Форма обратной связи',
        en: 'Feedback form',
        uz: 'Aloqa shakli',
        lt: 'Atgalinės ryšio formą'
    });

    const [data, setData] = useDataStateUpdater<AppealFormDataClient>(formInitData);

    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const removeError = () => {
        setErrors(null);
        setValidatorResult(new ValidatorResult<AppealFormDataClient>());
    };

    const sendForm = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        const formValidatorResult = validator.validate(data);
        setValidatorResult(formValidatorResult);
        if(!formValidatorResult.isSuccess()) {
            return;
        }

        const body: AppealRequest = {
            question: data.question,
            name: data.name,
            phone: data.phone.number,
            siteVersion: process.env.NEXT_PUBLIC_SITE as string
        };
        setIsLoading(true);

        MetricHelper.sendComagicApplication({
            name: data.name,
            phone: data.phone.number,
            message: data.question
        });

        apiService.sendAppealForm(body).then((response)=>{
            if(response.isSuccess()) {
                setIsSuccess(true);
            } else {
                setErrors(ApiErrorAdapter.getPayAssistantError(response.getError()));
            }
        }).finally(()=>{
            setIsLoading(false);
        });
    };


    const getPersonalDataLink = () => {

        const text = texts.personalDataText[language] as string;
        const textBefore = text.substring(0, text.indexOf('{}'));
        const textAfter = text.substring(text.indexOf('{}') + 2);

        return (
            <>
                {textBefore}
                <LinkButton as='Link' href={site === SiteType.RU ? '' : '/'} target={'_blank'}>
                    {texts.personalDataLink[language]}
                </LinkButton>
                {textAfter}
            </>
        );
    };

    return (
        <>
            <ModalWindow isOpen={isOpen.isOpen && !isSuccess} onClose={() => setIsOpen(prev => ({...prev, isOpen: false}))}>
                <Container
                    onSubmit={(e)=>sendForm(e)}>
                    <CloseButton 
                        data-test-id='applicationModalCloseButton'
                        type='button'
                        aria-label={labels.windowClose[language]}
                        onClick={() => setIsOpen(prev => ({...prev, isOpen: false}))}>
                        <CloseIcon/>
                    </CloseButton>
                    <Heading2>
                        {isOpen.title?.[language]}
                    </Heading2>
                    <FormFields>
                        <SimpleInputWidthEntryError
                            removeError={removeError}
                            placeholder={texts.name[language] as string}
                            fieldName={'name'}
                            onChange={(value)=>setData('name', value)}
                            validatorResult={validatorResult}
                            error={errors}
                            value={data.name}
                        />
                        <PhoneInputWidthEntryError
                            removeError={removeError}
                            value={data.phone}
                            name={'phone'}
                            placeholder={texts.phone[language] as string}
                            onChange={(value) => {
                                setData('phone', value);
                            }}
                            validatorResult={validatorResult}
                            error={errors}
                        />
                        <Input
                            placeholder={texts.question[language] as string}
                            setValue={(value)=>setData('question', value)}
                            isError={validatorResult.hasError('question')}
                            errorMessage={validatorResult.getMessageByLanguage('question', language)}
                            name='question'
                            value={data.question}
                        />
                        <LoadingContainer isLoading={isLoading}>
                            <Spinner size={50} loading={isLoading}/>
                        </LoadingContainer>
                    </FormFields>
                    <Button 
                        data-test-id='applicationModalSubmitButton'
                        isWide 
                        isLoading={isLoading}
                        disabled={isLoading}>
                        {texts.button[language] as string}
                    </Button>
                    <AcceptText>
                        {getPersonalDataLink()}
                    </AcceptText>
                    {/*<AnyError error={errors} except={['question', 'phone', 'name']}/>*/}
                </Container>
            </ModalWindow>

            <ModalWindow isOpen={isOpen.isOpen && isSuccess} onClose={()=>setIsOpen(prev => ({...prev, isOpen: false}))}>
                <Container onSubmit={(e)=>e.preventDefault()}>
                    <AppealSuccess onClose={()=>setIsOpen(prev => ({...prev, isOpen: false}))}/>
                </Container>
            </ModalWindow>
        </>
    );
}


const Container = styled.form`
  position: relative;
  padding: 48px;
  background: #FFFFFF;
  border-radius: 20px;
  width: 100%;
  max-width: 456px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  @media (max-width: 400px) {
    padding: 24px;
    border-radius: 10px;
  }

  h2 {
    text-align: center;
  }
`;

const CloseButton = styled.button`
    cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormFields = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LoadingContainer = styled.div<{ isLoading: boolean }>`
  position: absolute;
  left: -15px;
  top: -15px;
  bottom: -15px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .5s;
  opacity: ${({isLoading}) => isLoading ? 1 : 0};
  visibility: ${({isLoading}) => isLoading ? 'visible' : 'hidden'};
  pointer-events: ${({isLoading}) => isLoading ? 'all' : 'none'};
  backdrop-filter: blur(3px);
`;

const AcceptText = styled.div`
  padding: 0;
  margin: 0;
  ${typography.body};
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.GRAY_600};
  text-align: center;
  
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;