import { ComagicApplicationData } from '@logic/declare/comagic';

export default abstract class MetricHelper {

    public static sendComagicApplication (data: Partial<ComagicApplicationData>) {      
        if(global?.Comagic && Comagic?.addOfflineRequest) {        
            Comagic.addOfflineRequest({
                name: data.name ?? '', 
                email: data.email ?? '', 
                phone: data.phone ?? '', 
                message: data.message ?? ''
            });
        }
    }
}
