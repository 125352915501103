import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import passwordRecovery from '@logic/language/ex/landing/auth/passwordRecovery';
import { ApiErrorAdapter } from '@logic/service/API/error/errorsAdapter';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';
import ApiService from '@logic/service/ApiService/ApiService';
import { LandingContext } from '@context/LandingContext';
import { usePaApiService } from '@hooks/usePaApiService';

import { useAppSelector } from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { IconPosition } from '@components/common/buttons/buttonStyles';
import AnyError from '@src/common/errors/AnyError/AnyError';
import PasswordInput from '@src/common/inputs/PasswordInput/PasswordInput';
import { AuthModalState } from '@src/landing/modals/auth/AuthModal';
import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { Colors } from '@src/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@src/styledComponents/base/functions';
import { typography } from '@src/styledComponents/base/typography';

import ArrIcon from '@public/icons/baseUI/arrow-icons/long_arrow.svg';

interface PasswordData {
    newPassword: string,
    repeatNewPassword: string
}

type Props = {
    token: string,
    email: string
};

export default function NewPasswordForm (props: Props) {
    const { token, email } = props;

    const router = useRouter();
    const api = usePaApiService(ApiService);
    const { language } = useAppSelector(state => state.generalStatesSlice);
    const { setAuthModalState } = useContext(LandingContext);
    const [newPassword, setNewPassword] = useState('');
    const [errorResponse, setErrorResponse] = useState<PayAssistantError | null>(null);
    const [validatorResult, setValidatorResult] = useState(new ValidatorResult<PasswordData>());
    const [loading, setLoading] = useState(false);

    const sendNewPassword: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const formValidatorResult: ValidatorResult<PasswordData> = new ValidatorResult<PasswordData>();

        setValidatorResult(formValidatorResult);

        if (!formValidatorResult.isSuccess()) {
            return;
        }

        setLoading(true);
        api.refreshPassword(token, email, newPassword)
            .then((response) => {
                if (response.isSuccess()) {
                    router.push('/')
                        .then(()=> setAuthModalState(AuthModalState.Login));
                } else {
                    setErrorResponse(ApiErrorAdapter.getPayAssistantError(response.getError()));
                }
            })
            .finally(()=> setLoading(false));
    };

    return (
        <Content>
            <Form onSubmit={sendNewPassword}>
                <Heading>
                    <Title>
                        {passwordRecovery.resetPasswordHeading[language]}
                    </Title>
                    <Text>
                        {passwordRecovery.resetPasswordMessage[language]}
                    </Text>
                </Heading>
                <ResetPasswordForm>
                    <PasswordInput
                        label={passwordRecovery.labelPassword[language]}
                        name='newPassword'
                        value={newPassword}
                        isError={validatorResult.hasError('newPassword')}
                        errorMessage={validatorResult.getMessageByLanguage('newPassword', language)}
                        setValue={(value)=>setNewPassword(value)}/>
                    <AnyError error={errorResponse}/>
                </ResetPasswordForm>
                <ButtonContainer>
                    <Button
                        styles={buttonStyles}
                        type='submit'
                        isLoading={loading}
                        Icon={<ArrIcon/>}
                        iconPosition={IconPosition.RIGHT}>
                        {passwordRecovery.resetPasswordButton[language]}
                    </Button>
                </ButtonContainer>
            </Form>
        </Content>
    );
}

const Content = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
`;

const Form = styled.form`
    width: 300px;
    display: grid;
    align-items: flex-start;
    gap: 16px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 260px;
        gap: 32px;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
`;

const buttonStyles = css`
    width: 260px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        width: 190px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        width: 156px;
    }
`;

const Heading = styled.div`
    width: 100%;
    display: grid;
    gap: 8px;
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Text = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_900};
`;

const ResetPasswordForm = styled.div`
    width: 100%;
`;
