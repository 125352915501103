import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

export default class CheckedRule implements IRule
{
    public check (value : any) : boolean
    {
        return !!value;
    }

    public message () : RuleMessage
    {
        return {
            ru: `Поле не может быть пустым`,
            en: `The field cannot be empty`,
            lt: `Laukas gali būti tuščias`,
            uz: `Maydon bo'sh bo'lishi mumkin emas`
        };
    }
}