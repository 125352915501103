import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

export default class NoWhiteSpaceRule implements IRule
{

    public check (value : any) : boolean
    {
        return value.match(/\s+/) === null;
    }

    public message () : RuleMessage
    {
        return {
            ru: `В поле не должно быть пробелов`,
            en: `There should be no spaces in the field`,
            lt: `Lauke neturėtų būti spragų`,
            uz: `Maydonda bo'shliqlar bo'lmasligi kerak`
        };
    }
}