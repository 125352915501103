import { useContext } from 'react';
import styled, { css } from 'styled-components';

import registration from '@logic/language/ex/landing/auth/registration';
import { RegistrationSteps } from '@logic/models/Auth/AuthSteps';
import { UserRole } from '@logic/models/Auth/UserRole';

import { useAppSelector } from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { IconPosition } from '@components/common/buttons/buttonStyles';
import { RegistrationContext } from '@src/landing/modals/auth/registration/RegistrationContext';
import { Breakpoints } from '@src/styledComponents/base/breakpoints';
import { Colors } from '@src/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@src/styledComponents/base/functions';
import { typography } from '@src/styledComponents/base/typography';

import ArrIcon from '@public/icons/baseUI/arrow-icons/long_arrow.svg';

export default function RoleSelector () {

    const { language } = useAppSelector((state) => state.generalStatesSlice);
    const {registrationData, setRegistrationData, setStep} = useContext(RegistrationContext);

    const setRole = (role: UserRole) => {
        setRegistrationData((prev) => {
            const newState = {...prev};
            newState.role = role;
            return newState;
        });
    };

    return (
        <>
            <ChoosingRole>
                <Title>
                    {registration.roleSelectionHeading[language]}
                </Title>
                <Roles>
                    <Role
                        className={`${registrationData.role === UserRole.Company ? `roleChosen` : ``}`}
                        onClick={() => setRole(UserRole.Company)}
                    >
                        <RoleCompany/>
                        <Paragraph>
                            {registration.roleSelectionCardCompany[language]}
                        </Paragraph>
                    </Role>
                    <Role
                        className={`${registrationData.role === UserRole.Freelancer ? `roleChosen` : ``}`}
                        onClick={() => setRole(UserRole.Freelancer)}
                    >
                        <RoleFreelancer/>
                        <Paragraph>
                            {registration.roleSelectionCardFreelancer[language]}
                        </Paragraph>
                    </Role>
                </Roles>
                <ButtonContainer>
                    <Button
                        styles={buttonStyles}
                        Icon={<ArrIcon/>}
                        iconPosition={IconPosition.RIGHT}
                        onClick={() => setStep(RegistrationSteps.FillUserData)}
                        disabled={registrationData.role === UserRole.NotSelected}>
                        {registration.buttonNext[language]}
                    </Button>
                </ButtonContainer>
            </ChoosingRole>
        </>
    );
}

const ChoosingRole = styled.div`
    width: 100%;
    max-width: 416px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        max-width: 316px;
    }
`;

const Title = styled.div`
    ${typography.heading1};
    color: ${Colors.GRAY_900};
`;

const Roles = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        gap: 4px;
    }
`;

const Role = styled.div`
    width: 100%;
    max-width: 200px;
    max-height: 222px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    border: 1px solid ${Colors.GRAY_400};
    border-radius: 20px;
    gap: 20px;

    ${mediaBreakpointDown(Breakpoints.xTablet)} {
        max-height: 220px;
        max-width: 150px;
    }

    ${mediaBreakpointDown(Breakpoints.xMobile)} {
        max-height: 184px;
    }

    :hover {
        box-shadow: 0 4px 24px rgba(17, 26, 59, 0.1);
        border: 1px solid ${Colors.EMERALD};
        transition: border .5s, box-shadow .5s;
    }
    
    &.roleChosen {
        box-shadow: 0 4px 24px rgba(17, 26, 59, 0.1);
        border: 1px solid ${Colors.EMERALD};
    }
`;

const RoleCompany = styled.div`
    background: url("/landing/modalAuth/companyRoleIcon.svg") no-repeat;
    background-size: contain;
    width: 92px;
    height: 102px;
    flex-shrink: 0;
`;

const RoleFreelancer = styled.div`
    background: url("/landing/modalAuth/freelancerRoleIcon.svg") no-repeat;
    background-size: contain;
    width: 92px;
    height: 102px;
    flex-shrink: 0;
`;

const Paragraph = styled.div`
    ${typography.body};
    color: ${Colors.GRAY_700};
`;

const ButtonContainer = styled.div`
    display: grid;
    justify-content: center;
`;

const buttonStyles = css`
        width: 260px;

        ${mediaBreakpointDown(Breakpoints.xTablet)} {
            width: 190px;
        }

        ${mediaBreakpointDown(Breakpoints.xMobile)} {
            width: 156px;
        }
    }
`;
