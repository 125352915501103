import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';

import ValidatorResult from '@logic/forms/validators/result/ValidatorResult';
import Country from '@logic/models/Country/Country';
import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';

import {useAppSelector} from '@store/hooks/hooks';

import { Colors } from '@components/styledComponents/base/Colors';
import PhoneCountriesSelector from '@src/common/selectors/PhoneCountriesSelector/PhoneCountriesSelector';

import ErrIcon from '@public/icons/baseUI/alert-icons/alert_icon.svg';

export type Phone = {
    country: string,
    number: string,
};

export type PhoneNumber = {
    number: string,
    country: Country | null
};


export default function PhoneInputWidthEntryError<T> ({name, names, value, error, onChange, validatorResult, removeError, placeholder}: {
    value: Phone,
    name: keyof T,
    names?: Array<keyof T>,
    onChange: (value: any, fieldName?: string) => void;
    error: PayAssistantError | null,
    removeError(): void,
    placeholder?: string,
    validatorResult: ValidatorResult<T>,
}) {
    const { language } = useAppSelector((state) => state.generalStatesSlice);


    const [phone, setPhone] = useState<PhoneNumber>({
        number: ``,
        country: null
    });

    const [loading, setLoading] = useState<boolean>(true);

    const setCountry = (country: Country) => {
        setPhone((prev) => ({
            ...prev,
            country: country
        }));
    };

    const setPhoneNumber = (number: string) => {
        if(!phone.country) return;
        const clearNumber = number.replace(/[\D]+/g, '');
        setPhone((prev) => ({
            ...prev,
            number: clearNumber
        }));
    };

    const getPhoneWithoutCode = (number: string) : string => {
        if(!phone.country) return ``;
        return ('+' + number).replace(phone.country.code, ``);
    };

    const hideError = () => {
        if (hasError()) {
            removeError();
        }
    };

    useEffect(() => {
        if(loading && phone.country) {
            setPhoneNumber(getPhoneWithoutCode(value.number));
            setLoading(false);
        }
        if(!loading) {
            onChange({
                number: `${phone.country?.code}${phone.number}`,
                country: phone.country?.countryCode.alpha2 ?? ``
            });
        }
    }, [phone]);


    const hasError = () => {

        const isError = !!error?.hasValidationError(name as string);

        if(names && names.length > 1) {
            for(let i = 0; i < names.length; i++) {
                const err = !!error?.hasValidationError(names[i] as string) || validatorResult.hasError(names[i]) ;
                if(err) {
                    return err;
                }
            }
        }
        return validatorResult.hasError(name) || isError;
    };


    const getError = () => {
        if(error && error.hasValidationError(name as string)) {
            return error.getErrorTranslationByField(name as string, language);
        }
        if(validatorResult?.hasError(name)) {
            return validatorResult?.getMessage(name)![language];
        }
    };

    return (
        <FieldContainer hasName={false}>
            <PhoneContainer isLoading={loading}>
                <input
                    autoFocus={false}
                    type={`text`}
                    className={`input input_phone paragraph auth__input ${hasError() ? `input_error` : ``}`}
                    placeholder={placeholder ?? 'phone'}
                    value={phone.number}
                    onFocus={hideError}
                    onChange={(e) => setPhoneNumber(e.target.value.replaceAll(' ', ''))}
                />
                <PhoneCountriesSelector
                    propCountry={value.country}
                    onSelect={setCountry}
                />
                <ErrorContainer hasError={hasError()} onClick={hideError} >
                    {getError()}
                    <ErrIcon />
                </ErrorContainer>
            </PhoneContainer>
        </FieldContainer>
    );
}

const ErrorContainer = styled.div<{hasError: boolean}>`
  cursor: pointer;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;

  backdrop-filter:  blur(3px);

  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  text-align: right;

  color: ${Colors.DANGER};
  font-size: 12px;
  line-height: 1;

  transition: opacity .5s, visibility .5s;
  visibility: ${({hasError}) => hasError ? 'visible' : 'hidden'};
  opacity: ${({hasError}) => hasError ? '1' : '0'};
  pointer-events: ${({hasError}) => hasError ? 'all' : 'none'};

  &::first-letter {
    text-transform: uppercase;
  }


  svg {
    width: 18px;
    height: 18px;
    stroke: ${Colors.DANGER};
  }
`;

const FieldContainer = styled.div<{hasName: boolean}>`
  display: grid;
  grid-template-rows: 50px;
  grid-gap: 10px;
`;

const Highlights = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
`;

const PhoneContainer = styled.div<{isLoading: boolean}>`
  position: relative;
  height: 100%;
  animation-name: ${Highlights};

  input {
    background-color: ${Colors.WHITE}
  }

  ${({isLoading}) => {
        if(isLoading) {
            return `
              border-radius: 10px;
              animation-duration: 0.7s;
              
              animation-iteration-count: infinite;
              animation-direction: alternate;
              animation-timing-function: ease-in-out;
              
                & * {
                display: none;
                }
          `;
        }
        return '';
    }}

`;